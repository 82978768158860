<!--门禁模块--门禁时间表-->
<template>
  <div
    id="doorTimetable"
    v-loading="loading"
    :element-loading-text="$t('doortimetable.a1')"
  >
    <!-- 侧边导航栏 -->
    <div class="aside">
      <my-navder :list="list" :openKeys="openKeys" @select="getMsgFormSon" />
    </div>
    <!-- 右侧主要区域 -->
    <div class="right">
      <div class="main" ref="main" v-if="id">
        <!-- 右侧头部 -->
        <div class="main-header">
          <my-headertitle>{{ $t("doortimetable.a2") }}</my-headertitle>
          <div class="info">
            <div>
              <span>{{ $t("doortimetable.a3") }}</span>
              <span>{{ name }}</span>
              <a-icon
                type="form"
                @click="openNameUpdate"
                :style="{
                  fontSize: '15px',
                  color: '#7682CE',
                  'margin-left': '15px',
                }"
              />
            </div>
            <div>
              <ul class="templatetitle-right" v-if="dialogType == '1'">
                <li>
                  <div class="upright" style="background: #0fce0f"></div>
                  {{ $t("doortimetable.a6") }}
                </li>
                <li>
                  <div class="upright" style="background: #f8b62d"></div>
                  {{ $t("doortimetable.a7") }}
                </li>
                <li>
                  <div class="upright" style="background: #eeee00"></div>
                  {{ $t("doortimetable.a8") }}
                </li>
                <li>
                  <div class="upright" style="background: #7682ce"></div>
                  {{ $t("doortimetable.a9") }}
                </li>
                <li>
                  <div class="upright" style="background: #00a0e9"></div>
                  {{ $t("doortimetable.a10") }}
                </li>
              </ul>
              <ul class="templatetitle-right" v-if="dialogType == '2'">
                <li>
                  <div class="upright" style="background: #0fce0f"></div>
                  {{ $t("doortimetable.a11") }}
                </li>
                <li>
                  <div class="upright" style="background: #f8b62d"></div>
                  {{ $t("doortimetable.a12") }}
                </li>
                <li>
                  <div class="upright" style="background: #eeee00"></div>
                  {{ $t("doortimetable.a13") }}
                </li>
              </ul>
              <ul class="templatetitle-right" v-if="dialogType == '3'">
                <li>
                  <div class="upright" style="background: #0fce0f"></div>
                  {{ $t("doortimetable.a14") }}
                </li>
                <li>
                  <div class="upright" style="background: #f8b62d"></div>
                  {{ $t("doortimetable.a15") }}
                </li>
              </ul>
              <ul class="templatetitle-right" v-if="dialogType == '4'">
                <li>
                  <div class="upright" style="background: #0fce0f"></div>
                  {{ $t("doortimetable.a16") }}
                </li>
                <li>
                  <div class="upright" style="background: #f8b62d"></div>
                  {{ $t("doortimetable.a17") }}
                </li>
                <li>
                  <div class="upright" style="background: #eeee00"></div>
                  {{ $t("doortimetable.a18") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 单日时间设置列表 -->
        <div class="main-timetable">
          <my-timetable
            :data="data"
            :type="dialogType"
            :id="scheId"
            @getData="getData"
         />
        </div>
      </div>
      <!-- 无数据界面 -->
      <div class="main-show" v-else>
        <a-icon type="calendar" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{ $t("doortimetable.a25") }}</span>
      </div>
      <!-- 底部按钮 -->
      <div class="footer" v-if="id">
        <a-button type="primary" @click="allCopyTt">{{
          $t("doortimetable.a19")
        }}</a-button>
        <a-button type="primary" @click="copyTt">{{
          $t("doortimetable.a20")
        }}</a-button>
      </div>
    </div>
    <!-- 时间表全表复制 -->
    <a-modal
      :title="$t('doortimetable.a21')"
      :width="400"
      centered
      :visible="visible1"
      :destroyOnClose="true"
      @cancel="allHandleCancel"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="allHandleCancel" :disabled="disabled">
          {{ $t("doortimetable.a23") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="allHandleOk"
          :disabled="disabled"
        >
          {{ $t("doortimetable.a22") }}
        </a-button>
      </template>
      <div
        v-loading="loading_times"
        :element-loading-text="$t('doortimetable.a1')"
      >
        <div class="modal1">
          <span>{{ $t("doortimetable.a24") }}</span>
        </div>
        <a-select
          :placeholder="$t('doortimetable.a25')"
          v-model="modalKey1"
          style="width: 150px"
        >
          <a-select-option v-for="item in modal1" :key="item.key">
            {{ item.title }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
    <!-- 时间表复制 -->
    <a-modal
      :title="$t('doortimetable.a26')"
      :width="600"
      centered
      :visible="visible2"
      :destroyOnClose="true"
      @cancel="handleCancel"
      :getContainer="() => this.$refs.main"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="handleCancel" :disabled="disabled">
          {{ $t("doortimetable.a23") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="handleOk"
          :disabled="disabled"
        >
          {{ $t("doortimetable.a22") }}
        </a-button>
      </template>
      <div
        v-loading="loading_time"
        :element-loading-text="$t('doortimetable.a1')"
      >
        <div>
          <span>{{ $t("doortimetable.a27") }}</span>
          <a-select
            :placeholder="$t('doortimetable.a25')"
            v-model="srcDay"
            style="width: 150px; margin: 0px 20px"
          >
            <a-select-option v-for="item in week" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <span>{{ $t("doortimetable.a28") }}</span>
          <a-select
            :placeholder="$t('doortimetable.a25')"
            v-model="beginDay"
            style="width: 150px; margin: 10px 20px"
          >
            <a-select-option v-for="item in week" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <span>~</span>
          <a-select
            :placeholder="$t('doortimetable.a25')"
            v-model="endDay"
            style="width: 150px; margin: 10px 20px"
          >
            <a-select-option v-for="item in week" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
    <!-- 时间表名称更新 -->
    <a-modal
      :visible="nameVisible"
      :title="$t('doortimetable.a46')"
      :width="300"
      centered
      :destroyOnClose="true"
      @cancel="amendCancel"
      v-drag
    >
      <template slot="footer">
        <a-button key="submit" @click="amendCancel" :disabled="disabled">
          {{ $t("doortimetable.a23") }}
        </a-button>
        <a-button
          key="back"
          type="primary"
          @click="updateName"
          :disabled="disabled"
        >
          {{ $t("doortimetable.a22") }}
        </a-button>
      </template>
      <div
        v-loading="nameloaing"
        :element-loading-text="$t('doortimetable.a1')"
        class="modelName"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          layout="horizontal"
          :rules="rules"
        >
          <a-form-model-item :label="$t('doortimetable.a3')" prop="initialname">
            <a-input
              v-model="form.initialname"
              allow-clear
              :maxLength="30"
              ref="input"
              :placeholder="$t('doortimetable.a45')"
              v-InputText
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import Navdar from "../../components/Navdar/vertical";
import headerTitle from "../../components/Title/headerTitle";
import timetableModule from "./components/timetableModule";
import {
  doorTimetableListKT,
  getDID,
  doorTtUpdateName,
  copyAllTt,
  copyOneTt,
} from "../../api/door";

export default {
  name: "doorTimetable",
  data() {
    return {
      name: "",
      list: [],
      form: {
        initialname: "",
      },
      id: undefined,
      loading: false,
      loading_times: false,
      loading_time: false,
      nameloaing: false,
      data: undefined,
      dialogType: "",
      scheId: "",
      visible1: false,
      visible2: false,
      modal1: [],
      disabled: false,
      modalKey1: undefined,
      srcDay: undefined,
      beginDay: undefined,
      endDay: undefined,
      nameVisible: false,
      week: [
        { name: this.$t("doortimetable.a29"), value: 0 },
        { name: this.$t("doortimetable.a30"), value: 1 },
        { name: this.$t("doortimetable.a31"), value: 2 },
        { name: this.$t("doortimetable.a32"), value: 3 },
        { name: this.$t("doortimetable.a33"), value: 4 },
        { name: this.$t("doortimetable.a34"), value: 5 },
        { name: this.$t("doortimetable.a35"), value: 6 },
        { name: this.$t("doortimetable.a36"), value: 7 },
        { name: this.$t("doortimetable.a37"), value: 8 },
        { name: this.$t("doortimetable.a38"), value: 9 },
        { name: this.$t("doortimetable.a39"), value: 10 },
        { name: this.$t("doortimetable.a40"), value: 11 },
      ],
      rules: {
        initialname: [
          {
            required: true,
            message: this.$t("doortimetable.a45"),
            trigger: "change",
          },
        ],
      },
      openKeys:[]
    };
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {
      this.loading = true;
      let number = this.id.split(",");
      this.dialogType = number[0];
      this.scheId = number[1];
      let data = {
        scheId: number[1],
        scheType: number[0],
      };
      getDID(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.data = data;
          this.name = data.name;
          this.$refs.main.scrollTop = 0;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();
  },
  methods: {
    // 获取页面左侧列表方法
    navderList() {
      this.loading = true;
      let data = {
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        schType: 0,
      };
      doorTimetableListKT(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.list.push(data);
          this.openKeys.push(data.key)
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 加载页面数据的方法
    getData() {
      this.loading = true;
      let data = {
        scheId: this.scheId,
        scheType: this.dialogType,
      };
      getDID(data)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.data = data;
          this.name = data.name;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 打开时间表名称更新对话框
    openNameUpdate() {
      this.nameVisible = true;
      this.form.initialname = this.name;
      setTimeout(() => {
        this.$refs.input.focus();
      }, 5);
    },
    // 取消修改名称的方法
    amendCancel() {
      this.nameVisible = false;
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      this.id = data;
    },
    // 页面更新name成功后更新类表名字
    alterName(arr) {
      let id = this.id;
      let name = this.form.initialname;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].key == id) {
          arr[i].title = name;
        }
        if (arr[i].children) {
          this.alterName(arr[i].children);
        }
      }
    },
    // 更新时间表名称
    updateName() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.nameloaing = true;
          this.disabled = true;
          let scheId = this.scheId;
          let scheType = this.dialogType;
          let newName = this.form.initialname;
          let data = {
            scheId,
            scheType,
            newName,
          };
          doorTtUpdateName(data)
            .then((res) => {
              this.nameloaing = false;
              this.disabled = false;
              if (res.errorCode == "00") {
                this.alterName(this.list);
                this.amendCancel();
                this.$message.success(res.msg);
                this.name = newName;
              } else if (res.errorCode == "02") {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.disabled = false;
              this.nameloaing = false;
              this.amendCancel();
            });
        } else {
          return false;
        }
      });
    },
    // 时间表复制(整表复制)
    allCopyTt() {
      this.visible1 = true;
      this.modalKey1 = undefined;
      const id = this.id.split(",")[0];
      const _list = JSON.stringify(this.list[0].children);
      const list = JSON.parse(_list);
      for (let i = 0; i < list.length; i++) {
        if (list[i].key == id) {
          let timetable = list[i].children;
          let key = this.id;
          let result = timetable.findIndex((row) => row.key === key);
          timetable.splice(result, 1);
          this.modal1 = timetable;
        }
      }
    },
    // 时间表复制(整表复制)确定按钮
    allHandleOk() {
      let srcId = this.modalKey1;
      let targetId = this.scheId;
      let scheType = this.dialogType;
      if (srcId !== undefined) {
        this.disabled = true;
        this.loading_times = true;
        let data = {
          srcId: srcId.split(",")[1],
          targetId,
          scheType,
        };
        copyAllTt(data)
          .then((res) => {
            this.disabled = false;
            this.loading_times = false;
            if (res.errorCode == "00") {
              this.visible1 = false;
              this.getData();
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.disabled = false;
            this.loading_times = false;
          });
      } else {
        this.$error({
          title: this.$t("doortimetable.a42"),
          centered: true,
        });
      }
    },
    // // 时间表复制(整表复制)取消按钮
    allHandleCancel() {
      this.visible1 = false;
    },
    // 时间表复制(表内复制)
    copyTt() {
      this.visible2 = true;
    },
    // 时间表复制(表内复制)确定按钮
    handleOk() {
      let _b = this.beginDay;
      let _e = this.endDay;
      let _c = this.srcDay;
      if (_b == undefined || _e == undefined || _c == undefined) {
        this.$error({
          title: this.$t("doortimetable.a43"),
          centered: true,
        });
      } else if (_b < _e || _b == _e) {
        this.loading_time = true;
        this.disabled = true;
        let data = {
          schId: this.scheId,
          srcDay: this.srcDay,
          beginDay: this.beginDay,
          endDay: this.endDay,
          scheType: this.dialogType,
        };
        copyOneTt(data)
          .then((res) => {
            this.loading_time = false;
            this.disabled = false;
            if (res.errorCode == "00") {
              this.handleCancel();
              this.getData();
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.loading_time = false;
            this.disabled = false;
          });
      } else {
        this.$error({
          title: this.$t("doortimetable.a41"),
          centered: true,
        });
      }
    },
    // 时间表复制(表内复制)取消按钮
    handleCancel() {
      this.visible2 = false;
      this.srcDay = undefined;
      this.beginDay = undefined;
      this.endDay = undefined;
    },
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": Navdar,
    "my-headertitle": headerTitle,
    "my-timetable": timetableModule,
  },
};
</script>
<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#doorTimetable {
  width: 100%;
  height: 100%;
  display: flex;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main-header{
  height: 90px;
  padding: 0px 15px;
}
.main-timetable{
  height: calc(100% - 90px);
  padding: 5px 15px 0px 15px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.templatetitle-right {
  display: flex;
  align-items: center;
  margin: 0;
}
.templatetitle-right li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.upright {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.modelName {
  height: 80px;
  display: flex;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
.footer button {
  margin: 0px 10px;
}
.modal1 {
  padding-bottom: 15px;
}
</style>