<!--门禁模块--门禁时间表-->
<template>
  <div id="timetablemodule">
    <!-- 1 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a29") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.monday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.monday, 0, $t('doortimetable.a29'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 2 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a30") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.tuesday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.tuesday, 1, $t('doortimetable.a30'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 3 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a31") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.wednesday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.wednesday, 2, $t('doortimetable.a31'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 4 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a32") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.thursday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.thursday, 3, $t('doortimetable.a32'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 5 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a33") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.friday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.friday, 4, $t('doortimetable.a33'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 6 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a34") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.saturday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.saturday, 5, $t('doortimetable.a34'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 7 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a35") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.sunday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.sunday, 6, $t('doortimetable.a35'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 8 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a36") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.holiday"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.holiday, 7, $t('doortimetable.a36'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 9 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a37") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay1"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay1, 8, $t('doortimetable.a37'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 10 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a38") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay2"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay2, 9, $t('doortimetable.a38'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 11 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a39") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay3"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay3, 10, $t('doortimetable.a39'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 12 -->
    <div class="timetablemodule-row">
      <div class="timetablemodule-row-one">{{ $t("doortimetable.a40") }}</div>
      <div class="timetablemodule-row-two">
        <my-showtime :data="data.specialDay4"></my-showtime>
      </div>
      <div class="timetablemodule-row-three">
        <a-icon
          type="form"
          @click="getTime(data.specialDay4, 11, $t('doortimetable.a40'))"
          :style="{ fontSize: '20px', color: '#7682CE', 'margin-left': '15px' }"
        />
      </div>
    </div>
    <!-- 时间表设置弹窗 -->
    <my-dialog
      :visible="visible"
      :data="dialogData"
      :name="data.name"
      :dialogType="type"
      :week="week"
      :scheId="id"
    ></my-dialog>
  </div>
</template>

<script>
import showTime from "../components/showTime";
import doorTtDialog from "../components/doorTtDialog";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      dialogData: {},
      week: 0,
    };
  },
  methods: {
    // 改变对话框状态的方法
    closeDialog() {
      this.visible = false;
    },
    // 加载页面数据的方法
    getData() {
      this.$emit("getData");
    },
    // 点击修改icon时加载对应的数据和修改对话框状态
    getTime(data, week) {
      // console.log(data, week);
      this.visible = true;
      this.dialogData = data;
      this.week = week;
    },
  },
  components: {
    "my-showtime": showTime,
    "my-dialog": doorTtDialog,
  },
};
</script>

<style>
#timetablemodule {
  width: 100%;
}
.timetablemodule-row {
  width: 100%;
  height: 55px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #fff;
  box-shadow: 0 0px 5px #bdbcbc;
  border-radius: 3px;
}
.timetablemodule-row-one {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-right: 1px solid #e7e8e8;
}
.timetablemodule-row-two {
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
  padding: 0px 30px;
}
.timetablemodule-row-three {
  width: 70px;
  display: flex;
  align-items: center;
}
</style>